<template>
  <div id="app">
    <img class="banner" src="./assets/bg-new.png" />
    <div class="header">
      <div class="v_title">西安趣威文化传媒有限公司</div>
      <span class="v_select" :class="currIndex === 0 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(0)">首页</span>
      <span style="color: #ffffff">|</span>
      <span class="v_select" :class="currIndex === 1 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(1)">产品</span>
      <span style="color: #ffffff">|</span>
      <span class="v_select" :class="currIndex === 2 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(2)">关于我们</span>
    </div>
    <div class="v_content" v-if="currIndex == 0">
      <div style="flex-direction: column; margin-left: 15%">
        <div style="font-size: 35px">简介</div>
        <div style="font-size: 25px">Introduce</div>
        <p style="font-size: 15px; margin-top: 15px; line-height: 25px">
          公司在商城优惠领域经过多年发展，现已在技术、产品、运营、商城领域积累丰富的经验，利用自己强大的产品开发能力和丰富的商品选购渠道，与商品平台合作，将优惠的商品呈现给目标用户，并取得不错的效果。并且公司拥有自己的产品开发团队和商品管理团队，未来将在商品选购、优惠场景、商品上新等多领域发展。同时公司在小说阅读领域也有着丰富的经验，利用自己强大的产品开发能力和丰富的内容分发渠道，与内容版权公司合作，将优秀的作品快速投入市场，并取得不错的效果。并且公司拥有自己的产品开发团队和版权运营团队，未来将在小说IP孵化、游戏影视剧改编、小说周边等多领域发展。
        </p>
      </div>
      <img style="margin-right: 25%; margin-left: 15%; width: 200px; height: 230px" src="./assets/bg02.jpg" alt="" />
    </div>
    <img src="./assets/ttlx-bg.jpg" alt="" v-if="currIndex == 1" style="height:1080px;width:100%" />
    <!-- <img src="./assets/ws-bg.png" alt=""  v-if="currIndex == 1" style="height:1080px;width:100%"/> -->
    <img src="./assets/cy-bg-new.jpg" alt="" v-if="currIndex == 1" style="height:1080px;width:100%" />
    <div class="v_content_About" v-if="currIndex == 2">
      <div style="flex-direction: column">
        <div style="font-size: 35px; margin-top: 30px">关于我们</div>
        <div style="font-size: 25px">About us</div>
        <div class="v_text">
          公司在商城优惠领域经过多年发展，现已在技术、产品、运营、商城领域积累丰富的经验，利用自己强大的产品开发能力和丰富的商品选购渠道，与商品平台合作，将优惠的商品呈现给目标用户，并取得不错的效果。并且公司拥有自己的产品开发团队和商品管理团队，未来将在商品选购、优惠场景、商品上新等多领域发展。同时公司在小说阅读领域也有着丰富的经验，利用自己强大的产品开发能力和丰富的内容分发渠道，与内容版权公司合作，将优秀的作品快速投入市场，并取得不错的效果。并且公司拥有自己的产品开发团队和版权运营团队，未来将在小说IP孵化、游戏影视剧改编、小说周边等多领域发展。
        </div>
        <div class="v_text">
          1. 深耕商城优惠市场，拓展商城优惠场景
          公司多年来致力于移动互联网商城产品的开发与运营，在团队建设、选品渠道、技术开发，产品运营等方面积累了丰富的经验，在此基础上向商城优惠领域延伸，开发多款商城类产品，依托公司成熟的选品渠道和强大的技术产品实力，将继续在商城领域拓展更多业务。
        </div>
        <div class="v_text">
          2. 资深的技术、内容、产品、运营、选品团队
          公司在原有基础上，拓展app开发、前端开发、大数据分析等人员，不断打磨产品，并吸纳商城领域资深运营、产品，不断创新选品策略，拥有优秀的设计师团队和选品团队，有力的推进了公司的发展。
        </div>
        <div class="v_text">
          3. 互利共赢，协同发展
          公司在商城优惠上除自己设计选品外，与国内外各大商品平台和优质商家合作，对其优惠商品进行呈现，利用自己的渠道对其推广，达到合作共赢的结果，未来将还会合作更多优惠商品以及优惠活动，创造更多的优惠场景。
        </div>
        <div class="v_text">
          4. 优惠选品，场景无限想象
          公司通过自主开发设计和与其它商品平台公司合作，对优惠商品进行展示处理、美化处理，并利用移动技术的力量，以图片、比价、精选、视频等形式进行商品推广和优惠扩充，打造包含商品图片、商品视频、商品比价、商品精选、商品场景、优惠力度、活动推广等多方面的产业链。
        </div>
        <div class="v_text">
          5. 深入阅读市场，拓展小说领域
          公司多年来致力于移动互联网电子阅读产品的开发与运营，在团队建设、分发渠道、技术开发，产品运营等方面积累了丰富的经验，在此基础上向小说领域延伸，开发多款小说类产品，依托公司成熟的推广渠道和强大的技术产品实力，将继续在小说领域拓展更多业务，服务更多用户。
        </div>
        <div class="v_text">
          6. 公司在小说内容上在自己设计创作外，与国内外各大漫画作者和小说公司合作，对其内容包装定位，利用自己的渠道对其推广，达到合作共赢的结果，未来将还会合作开发IP及其周边产品，创造更大的合作价值。
        </div>
        <div class="v_text">
          7. IP孵化，创作无限想象
          公司通过自主开发设计和与其它小说公司合作，对爆款小说内容进行影视剧、游戏改编，并利用移动新媒体的力量，以短视频、图文、有声、直播等形式进行推广和IP的培育，打造包含小说图文、小说视频、有声及小说、手游、小说周边、小说广告、小说教育等多方面的产业链，不断完善用户场景。
        </div>
      </div>
    </div>
    <div class="footr">
      <div>
        <p style="font-size: 15px">
          Copyright © 2022 西安趣威文化传媒有限公司
          版权所有备案编号：<a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2021001897号-1</a> | 陕网文〔2019〕5367-061号
        </p>
        <p style="font-size: 15px">
          网络文化经营许可证编号：陕网文（2023）2038-059号
        </p>
        <p style="font-size: 15px">
          公司地址：陕西省西安市曲江新区雁翔路黄渠头小区2号楼2单元2603室
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0,
    };
  },
  methods: {
    onClick(i) {
      this.currIndex = i;
    },
  },
};
</script>

<style>
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.banner {
  width: 100%;
  height: 580px;
}

.header {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
}

.v_title {
  font-size: 30px;
  color: aliceblue;
  font-weight: 600;
  margin-right: 100px;
}

.v_select {
  cursor: pointer;
  margin: 0% 5%;
  font-size: 15px;
  font-weight: 700;
}

.bg_yellow {
  color: yellow;
}

.bg_aliceblue {
  color: aliceblue;
}

.v_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.swper_view {
  display: flex;
  flex-direction: row;
}

.v_prodect {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.pro_img_view {
  display: flex;
  justify-content: space-around;
  margin-left: 15%;
  margin-right: 15%;
  height: 450px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.v_content_About {
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10px;
}

.footr {
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkgray;
  height: 100px;
}

.index_view {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.v_text {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
